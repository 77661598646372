import React from "react";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { BrandColours } from "../../utils/colours";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;
  width: 440px;
  height: 300px;
  min-width: 450px;

  border-radius: 24px;
  overflow: hidden;

  HighlightCard{
    display: flex;
    flex-direction: column;
  }

  .content {
    padding: 12px;
    width: 100%;
    color: #ffffff;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    background: ${BrandColours.green.baseTranparent};
    bottom:0;
  }

  .image {
    height: 300px;
    width: auto;
    margin-bottom: 0px;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
  }
  
  .Title {
    font-size: 1.5rem;
    font-weight: 700;
    opacity: 1;
    margin-bottom: 0px;
  }

  .author{
    margin: auto 0 8px 0;
    display: flex;
    flex-flow: row;
    p {
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 12px;
    }
  }

  p {
    font-size: 0.9rem;
    font-weight: 700;
  }

  @media screen and (max-width: 400px) {
    height: 180px;

    margin: 8px;
    min-width: 320px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16), 0 0px 0px rgba(0, 0, 0, 0.23);
    .image{
      height: 180px;
    }
    .content{
      padding: 0px 12px;
      justify-content: flex-start;
      .Title, .author {
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
      }
      .Title{
        font-size: 1rem;
        margin: 8px 0 8px 0;
      }
      .author {
        p{
        font-size: 12px;
        }
        margin: auto 0 8px 0;
      }
    }
  }

  
`

const BlogPostCard = ({ id, title, author, description, excerpt, date, readTime, image, slug }) => {
  const pluginImage= getImage(image)

    return (
        <Card key={id}>
            <a href={`${slug}`} className="CleanLink HighlightCard">
            <BgImage image={pluginImage} className="image" alt={description}>
            <div className="content">
                <p className="Title">{title}</p>
                <div className="author">
                  <p>By {author}</p>
                  <p>{date} &bull; {readTime}</p>
                </div>
            </div>
            </BgImage>
            </a>
        </Card>
    )
}

export default BlogPostCard;