import React, { useState } from "react";
import styled from "styled-components";
import { BrandColours } from "../../utils/colours";
import * as queryString from "query-string";
import { useFlexSearch } from "react-use-flexsearch";
import BlogPostCard from "../blog/blogPostCard";

const SearchBar = styled.div`
  display: flex;
  border-radius: 10px;
  margin: 0px auto;
  max-width: 1000px;
  height: 3rem;
  background: ${({ theme }) => theme.purpleTextBackground};
  margin-bottom: 20px;

  @media screen and (max-width: 1060px){
    margin: 0px 30px;
  }

  input {
    display: flex;
    flex: 100%;
    height: 100%;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: ${BrandColours.white.base};
    word-wrap: break-word;
    outline: none;
  }
`;

const ResultsContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 48px;

  @media screen and (max-width: 1024px){
    justify-content: center;
  }
`;

const SearchedPosts = ({ results }) => 
  results.length > 0 ? (
    results.map(post => {
      console.log(post.image)
        return (
          <BlogPostCard 
            id={post.id} 
            title={post.title} 
            author={post.author} 
            date={post.date}
            description={post.description} 
            excerpt={post.excerpt} 
            image={post.image} 
            slug={post.slug}
          />
        )
      })
    ) : (
      <p>No posts found.</p>        
  );

const AllPosts = ({ posts }) => (
  posts.map(post => {

    return (
      <BlogPostCard 
        id={post.node.id} 
        title={post.node.frontmatter.title} 
        author={post.node.frontmatter.author} 
        date={post.node.frontmatter.date}
        readTime={post.node.frontmatter.readTime}
        description={post.node.frontmatter.description} 
        excerpt={post.node.frontmatter.excerpt} 
        image={post.node.frontmatter.contentImageUrl}
        slug={post.node.fields.slug}
      />
    )
  })
);

const SearchPosts = ({ posts, localSearchBlog, location, navigate, searchPrefix }) => {

    const { search } = queryString.parse(location.search);
    const [query, setQuery] = useState(search || "");

    const results = useFlexSearch(
      query,
      localSearchBlog.index,
      localSearchBlog.store
    )

    return (
        <>
            <SearchBar>
                <input
                  type="search" 
                  placeholder="Search" 
                  name="BlogSearch"
                  value={query}
                  onChange={e => {
                    navigate(	
                      e.target.value ? `${searchPrefix}?search=${e.target.value}` : searchPrefix	
                    );
                    setQuery(e.target.value);
                    //If not empty show a little cross, otherwise no cross?
                  }}
                />
            </SearchBar>
            <ResultsContainer>
              {query ? <SearchedPosts results={results} /> : <AllPosts posts={posts} />}
            </ResultsContainer> 
        </>
    );
};

export default SearchPosts;