import React from "react";
import styled from "styled-components";
import BlogPostCard from "./blogPostHighlight";
// import SolaPostCard from "./solaPostHighlight";
import { BrandColours } from "../../utils/colours";

const Row = styled.div`
    margin: 0px 0 12px 0;
    width: 100%;
    position: relative;
    overflow: auto;
    

    .no-posts {
      margin-top: 8px;
    }
`;

const SpacerDiv = styled.div`
min-width: 1px;
margin:0px;
height:auto;
`;

const PostsContainer = styled.div`
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px 20px 20px;

    ::-webkit-scrollbar {
      width: 10px;
    }
    
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${BrandColours.green.baseTranparent};
      border-radius: 10px; 
    }

    @media screen and (max-width: 1420px) {
      justify-content: flex-start;
      padding: 20px 0px;
    }

    @media screen and (max-width: 400px) {
      padding: 0px;
    }

`;

const BlogPagePostRow = ({ posts }) => {
  return(
    <Row>
      {posts.length > 0 ?
        (<PostsContainer>
          {/* <SolaPostCard/> */}
          { posts.map(post => {
            return (
              <BlogPostCard 
              id={post.node.id} 
              title={post.node.frontmatter.title} 
              author={post.node.frontmatter.author} 
              date={post.node.frontmatter.date}
              readTime={post.node.frontmatter.readTime}
              description={post.node.frontmatter.description} 
              excerpt={post.node.frontmatter.excerpt} 
              image={post.node.frontmatter.contentImageUrl} 
              slug={post.node.fields.slug}
              />
              )
            }) }
            <SpacerDiv> </SpacerDiv>
        </PostsContainer>)
      : <p className="no-posts">More post coming soon.</p>}
    </Row>
  );
}

export default BlogPagePostRow;