import React from "react";
import Newslettersignup from "../components/Newslettersignup";
import SEO from "../components/seo";
import SearchPosts from "../components/Home/searchPostsHM";
import BlogPostHighlights from "../components/blog/blogPagePosthighlightRow"
import Layout from "../components/layout";
import styled from "styled-components";
import { BrandColours, TextColours } from "../utils/colours";
import { graphql } from 'gatsby'

const BodyWrapper = styled.div`
  margin: 0 auto;
  height: auto;
  max-width: 1500px;
  min-height: 100vh;

  .blogs-section {
    padding: 88px 24px;
  }
`;

const TopPicks = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
    margin-top: 85px;
    border-radius: 20px;

  h1 {
    font-size: 44px;
    font-weight: bold;
    color: ${BrandColours.green.base};
    margin: 0 auto 24px 12px;

    @media screen and (max-width: 700px) {
      font-size: 32px;
      Margin-bottom: 0px;
    }

    @media screen and (max-width: 400px) {
      font-size: 24px;
    }
  }

`;

class IndexPage extends React.Component {

  render() {

    const { data, navigate, location } = this.props;
    const posts = data.allMdx.edges;
    const localSearchBlog = data.localSearchBlog;
    const featuredPosts = this.props.data.allMdx.edges;

    return (
      <main>
        <Layout location={this.props.location}>
          <SEO 
          title='Home'
          keywords={['anxious', 'extrovert', 'blog', 'breathing', 'mental', 'health', 'help', 'young', 'youngminds', 'student', 'mind', 'minds', 'exercises', 'services', 'blogs', 'calm', 'coping']}/>

          <BodyWrapper>
          <TopPicks>
            <h1>Featured</h1>
            <BlogPostHighlights posts={posts.filter(blog => blog.node.frontmatter.isFeatured === true)}/>

          </TopPicks>

              <SearchPosts posts={posts} location={location} navigate={navigate} localSearchBlog={localSearchBlog} searchPrefix="#blogs" />
              <Newslettersignup />
          </BodyWrapper>

        </Layout>
      </main>
    )
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        keywords
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(filter: {internal:{contentFilePath: {regex: "//blog/"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "Do MMMM YYYY")
            title
            description
            imageUrl { 
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 70}
                  layout: FULL_WIDTH
                  blurredOptions: {toFormat: WEBP, width: 10}
                  breakpoints: 1920
                )
              }
            }
            contentImageUrl { 
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 70}
                  layout: FULL_WIDTH
                  blurredOptions: {toFormat: WEBP, width: 10}
                  breakpoints: 1920
                )
              }
            }
            author
            readTime
            isFeatured
          }
        }
      }
    }
  }
`;
